function getUrlParameter(param) {
  var pageUrl = window.location.search.substring(1);
  var params = pageUrl.split('&');

  for (var i = 0; i < params.length; i++) {
    var parameterName = params[i].split('=');
    if (parameterName[0] === param) { return parameterName[1]; }
  }
}

var createUrl = function (base, paths, params) {
  if (arguments.length === 0) throw new Error('Base url or path is required.');

  var _url = base;
  var _paths = paths || [];
  var _params = params || {};

  for (key in _paths) {
    if (!!key) {
      var separator = _url.slice(-1) !== "/" ? "/" : "";
      _url += separator + _paths[key]
    }
  }

  for (key in _params) {
    if (!!_params[key]) {
      var separator = _url.indexOf('?') !== -1 ? "&" : "?";
      _url += separator + key + "=" + _params[key];
    }
  }

  return _url
}

// Util
function toMoney(value) {
  var amount = Number(value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return '$' + amount;
}

function serializeObject(form) {
  var _object = {};
  var _array = form.serializeArray();

  $.each(_array, function () {
    if (_object[this.name]) {
      if (!_object[this.name].push && _object[this.name] !== "0") {
        _object[this.name] = [_object[this.name]];
      }
      if (Array.isArray(_object[this.name])) {
        _object[this.name].push(this.value || "");
      } else {
        _object[this.name] = this.value || "";
      }
    } else {
      _object[this.name] = this.value || "";
    }
  });

  return _object
}

function toNumber(value) {
  return Number(value.replace(/[$,]+/g, ""));
}
jQuery.fn.toggleValidations = function() {
  var element = jQuery(this);

  if (element.rules().length > 0) {
    element.removeValidations();
  } else {
    element.restoreValidations();
  }
};

jQuery.fn.removeValidations = function() {
  var element = jQuery(this);
  element.data("stored_validations", element.rules());
  element.rules("remove");
};

jQuery.fn.restoreValidations = function() {
  var element = jQuery(this);
  var validations = element.data("stored_validations");
  if (validations !== undefined) {
    element.rules("add", validations);
  }
};

$(document).ready(function() {
  $.extend($.validator.messages, {
    required: $.polyglot.t('validations.required'),
    remote: $.polyglot.t('validations.remote'),
    email: $.polyglot.t('validations.email'),
    url: $.polyglot.t('validations.url'),
    date: $.polyglot.t('validations.date'),
    dateISO: $.polyglot.t('validations.dateISO'),
    number: $.polyglot.t('validations.number'),
    digits: $.polyglot.t('validations.digits'),
    creditcard: $.polyglot.t('validations.creditcard'),
    equalTo: $.polyglot.t('validations.equalTo'),
    accept: $.polyglot.t('validations.accept'),
    maxlength: $.validator.format($.polyglot.t('validations.maxlength')),
    minlength: $.validator.format($.polyglot.t('validations.minlength')),
    rangelength: $.validator.format($.polyglot.t('validations.rangelength')),
    range: $.validator.format($.polyglot.t('validations.range')),
    max: $.validator.format($.polyglot.t('validations.max')),
    min: $.validator.format($.polyglot.t('validations.min')),
    cvv: $.validator.format($.polyglot.t('validations.cvv')),
    ccExpiration: $.validator.format($.polyglot.t('validations.ccExpiration'))
  });

  $.validator.addMethod(
    "regex",
    function(value, element, regexp) {
      var mod = "";

      // if regexp starts with a forward slash, remove it and split on the last one
      if (regexp.charAt(0) === "/") {
        var tokens = regexp.split("/");
        tokens.shift();
        mod = tokens.pop();
        regexp = tokens.join("/");
      }

      var re = new RegExp(regexp, mod);
      return this.optional(element) || re.test(value);
    },
    $.polyglot.t('validations.check_input')
  );

  $.validator.addMethod(
    'imageFileExtension',
    function(value, element) {
      if (value === '') {
        return true;
      }
      var acceptableValues = ['jpg', 'jpeg', 'gif', 'pdf', 'png'];
      var splitValue = value.split('.');
      var extension = splitValue[splitValue.length - 1].toLowerCase();

      if (acceptableValues.indexOf(extension) != -1)
        return true;
      else
        return false;
    },
    $.polyglot.t('validations.image_file_extension')
  );

  $.validator.addMethod(
    'maxFileSize',
    function(value, element) {
      if (element.files) {
        if (element.files.length > 0 && element.files[0].size > 10485760)
          return false;
        else
          return true;
      } else {
        return true;
      }
    },
    $.polyglot.t('validations.max_file_size')
  );

  $.validator.addMethod('strictPhone', function(value, element) {
    return $(element).data("valid_phone");
  }, $.polyglot.t('validations.required'));

  $.validator.addMethod(
    'cvv',
    function(value, element, param) {
      var creditCard = $(param.creditCard).val();
      var cards = {
        amex: {
          length: [15],
          prefix: ['34', '37']
        },
        discover: {
          length: [16],
          prefix: ['6011', '622126', '622127', '622128', '622129', '62213',
                   '62214', '62215', '62216', '62217', '62218', '62219',
                   '6222', '6223', '6224', '6225', '6226', '6227', '6228',
                   '62290', '62291', '622920', '622921', '622922', '622923',
                   '622924', '622925', '644', '645', '646', '647', '648',
                   '649', '65']
        },
        mastercard: {
          length: [16],
          prefix: ['51', '52', '53', '54', '55']
        },
        visa: {
          length: [16],
          prefix: ['4']
        }
      };
      var type, i, creditCardType = null;

      if (!/^[0-9]{3,4}$/.test(value)) {
        return false;
      }

      if (creditCard === '') {
        return true;
      }

      for (type in cards) {
        for (i in cards[type].prefix) {
          if (creditCard.substr(0, cards[type].prefix[i].length) === cards[type].prefix[i] && $.inArray(creditCard.length, cards[type].length) !== -1) {
            creditCardType = type;
            break;
          }
        }
      }

      return (creditCardType === null) ? false : (('amex' === creditCardType) ? (value.length === 4) : (value.length === 3));
    },
    $.polyglot.t('validations.cvv')
  );

  $.validator.addMethod(
    "ccExpiration",
    function(value, element, params) {
      var minMonth = new Date().getMonth() + 1;
      var minYear = new Date().getFullYear();
      var formMonth = $(params.formMonth).val();
      var formYear = $(params.formYear).val();
      var month = parseInt(formMonth);
      var year = parseInt(formYear);

      if ((year > minYear) || ((year === minYear) && (month >= minMonth))) {
        return true;
      } else {
        return false;
      }
    },
    $.polyglot.t('validations.ccExpiration')
  );
});

jQuery.fn.exists = function(){return jQuery(this).length>0;}

jQuery.fn.registriaAutocomplete = function(options){
  var cache = {},
  lastXhr;

  var p = $.extend(true, {
    render: function(item){
      return item.label;
    }
  }, options || {});

  var ac_p = $.extend(true, {
    minLength: 3,
    source: function( request, response ) {
      var term = request.term;
      if ( term in cache ) {
        response( cache[ term ] );
        return;
      }

      if (RegExp('msie*').test(navigator.userAgent) && window.XDomainRequest) {
        // Use Microsoft XDR
        var xdr = new XDomainRequest();
        xdr.open("get", p.url + "?" + $.param(request));
        xdr.onload = function() {
          data = jQuery.parseJSON(xdr.responseText);
          cache[ term ] = data;
          response( data );
        };
        xdr.send();
      } else {
        lastXhr = $.getJSON(p.url, request, function( data, status, xhr ) {
          cache[ term ] = data;
          if ( xhr === lastXhr ) {
            response( data );
          }
        });
      }
    }
  }, p.options || {});

  var ac = jQuery(this).autocomplete(ac_p);

  ac.data("autocomplete")._renderItem = function( ul, item ) {
    return $("<li></li>")
    .data("item.autocomplete", item)
    .append(p.render(item))
    .appendTo(ul); // result of render into append
  };

  return ac;
}

$.extend({
  getUrlVars: function(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[decodeURIComponent(hash[0])] = decodeURIComponent(hash[1]);
    }
    return vars;
  },
  getUrlVar: function(name){
    return $.getUrlVars()[name];
  },
  objectSize: function(obj) {
      var size = 0, key;
      for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
      }
      return size;
  }
});

function setCookie(c_name,value,expiredays,path)
{
    if(path == undefined)
        path = "/";
    var exdate=new Date();
    exdate.setDate(exdate.getDate()+expiredays);
    document.cookie=c_name+ "=" +escape(value)+
    ((expiredays==null) ? "" : ";expires="+exdate.toUTCString())+
    ";path="+path;
}

function getCookie(c_name)
{
    if (document.cookie.length>0)
      {
      c_start=document.cookie.indexOf(c_name + "=");
      if (c_start!=-1)
        {
        c_start=c_start + c_name.length+1;
        c_end=document.cookie.indexOf(";",c_start);
        if (c_end==-1) c_end=document.cookie.length;
        return unescape(document.cookie.substring(c_start,c_end));
        }
      }
    return "";
}

function postwith (to, p) {
  var myForm = $("<form action='" + to + "' method='POST'></form>");

  $.each(p, function(i,element){
	  var element = $(element);
	  if(element.attr('type') == 'radio') {
	    if(element.attr('checked')) {
	      var newElement = element.clone(true);
  		  newElement.val(element.val());
  		  newElement.attr('checked', true);
  		  element.attr('checked', true);
    		newElement.appendTo(myForm);
	    }
    }
    else {
      var newElement = element.clone(true);
		  newElement.val(element.val());
  		newElement.appendTo(myForm);
    }
	});

  myForm.appendTo('body');
  myForm.submit();
  myForm.remove();
}

function post_to_url(path, params, method) {
  method = method || "post"; // Set method to post by default, if not specified.

  var form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  for(var key in params) {
    if(params.hasOwnProperty(key)) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

$(function() {
  $("#registria_form input:visible:first").trigger('focus');
  $('#registria_form').validate({
    errorClass: "invalid",
    submitHandler: function(form) {
      $("[type='submit']", form)
        .val("Please Wait...")
        .attr('disabled', 'disabled');
      form.submit();
    },
    errorPlacement: function(error, element) {
      if(element[0].type === "radio" && $(".survey-error").length) {
        error.appendTo($(".survey-error"));
        $('label.invalid').addClass("d-block mb-0 form-err-vis");
      } else if (element[0].type === "radio" && $(".shipping-method-error").length) {
        error.appendTo($(".shipping-method-error"));
        $('label.invalid').addClass("d-block mb-0 form-err-vis");
      } else {
        error.insertAfter(element);
      }
    },
    showErrors: function(errorMap, errorList) {
      if (!$.isEmptyObject(errorMap)) {
        $('#review_errors').removeClass('d-none');
        $(".survey-error").html('');
      } else {
        $(".survey-error").addClass('d-none');
      }
      this.defaultShowErrors();
    }
  });
});

$(window).on("pageshow", function(event) {
  if (event.originalEvent.persisted) {
    window.location.reload();
  }
});

var getConverter = function () {
  if($.converter) return $.converter;

  $.converter = new showdown.Converter();
  return $.converter;
}

var renderMarkdown = function(element, markdown) {
  if ( element.length && _.trim(markdown).length > 0 ) {
    $('.markdown-body', element).html(getMarkdown(markdown));
  }
}

var getMarkdown = function(markdown) {
  return getConverter().makeHtml(markdown);
}
